<template>
  <div class="about-container solution-container">
    <Header></Header>
    <div class="container">
      <div class="first">
        <h1 class="fs-64">配套服务</h1>
      </div>
      <div class="banner"></div>
      <div class="third">
        <div class="df ptb-100">
          <div><img src="../assets/img/server/s_0.jpg" alt="" /></div>
          <div>
            <h2 class="fs-38 mb-20">拖车提货</h2>
            <p class="fs-20">
              我们拥有多年的物流经验及稳定的运力资源，可以为客户提供专业的拖车提货服务，
              确保快速、安全地完成货物的装卸、运输、交接等各项工作。
            </p>
            <p class="fs-20 mt-10 mb-10">
              同时，我们也保证在接货、运输中对货物的安全把控，降低货物在运输过程中的受损风险。
            </p>
            <p class="fs-20">
              标准的危险品仓库拥有合法危险品仓储资质，以确保您的货物储存安全、规范、周到。
              货物查询系统，可以通过网络设备或是移动终端及时跟踪货物的基本状态，时刻确保货物安全。
            </p>
          </div>
        </div>
        <div class="df ptb-100">
          <div>
            <h2 class="fs-38 mb-20">门点装箱</h2>
            <p class="fs-20">
              提供从客户门点提箱、装箱、运输、拆箱、派送等系列服务，
              涵盖了流程服务，通过完善的操作流程、丰富的运输资源和科学的信息系统管理，
              我们可以确保客户货物在进行门点装箱服务过程中的安全性、完整性和时效性，并且根据客户需求量身定制解决方案。
            </p>
          </div>
          <div class="text-right">
            <img src="../assets/img/server/s-7.png" alt="" />
          </div>
        </div>
        <div class="df ptb-100">
          <div><img src="../assets/img/server/s_4.jpg" alt="" /></div>
          <div>
            <h2 class="fs-38 mb-20">仓库装箱</h2>
            <p class="fs-20">
              仓库装箱服务可以有效减少客户反复多次进行货物装卸流程，
              从而降低客户在运输过程中产生的成本，同时保障客户的货物在存储、装箱、配送等环节均能完成。
              在客户业务进行的过程中减少人工损耗，从而更高效地完成运输。
            </p>
          </div>
        </div>
        <div class="df ptb-100">
          <div>
            <h2 class="fs-38 mb-20">危险品申报</h2>
            <p class="fs-20">
              运输危险品时，需按照相关法规和规定，进行危险品申报和备案。我们为客户提供涉及到危险品的运输、进出口和仓储等全面服务。
            </p>
            <p class="fs-20  mt-10">
              我们的团队经验丰富，掌握危险品相关法规和行业标准，可以为客户提供危险品分类、严格的危险品申报、包装、标识、运输和操作指导等服务，
              确保符合相关中国和国际标准要求，并尽量减少危险品运输过程中的安全问题。
            </p>
          </div>
          <div class="text-right">
            <img src="../assets/img/server/s_3.jpg" alt="" />
          </div>
        </div>
        <div class="df ptb-100">
          <div><img src="../assets/img/server/s_2.jpg" alt="" /></div>
          <div>
            <h2 class="fs-38 mb-20">报关清关</h2>
            <p class="fs-20">
              在进出口贸易中所涉及到的海关申报和报关手续可提供报关清关服务，包括报关单填写、税则编码确定、证照审查、
              关税计算、结汇核销、进出口货物检验检疫等服务。
            </p>
            <p class="fs-20  mt-10 mb-10">
              我们的报关清关服务不仅包括普通贸易出口、进口报关，还包括保税区报关、加工贸易报关、备案报关等多种形式的业务。
            </p>
            <p class="fs-20">
              我们有经验丰富的服务运营团队，可以为客户提供便捷、专业的服务，使客户享受到优质的服务体验，促进提升经济效益。
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header";
import Footer from "../components/footer";

export default {
  name: "Solution",
  components: {
    Header,
    Footer,
  },
};
</script>
